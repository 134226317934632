export default {
  inject: ['getOrderInfo'],
  methods: {
    async sendFinalDraftDownloadEmail() {
      const info = await this.getOrderInfo()
      console.log(`info`, info)
      const { downloadTimes: t } = info
      console.error('sendFinalDraftDownloadEmail')
      if (this.$g.isCust) {
        console.log(`downloadTimes`, t)
        if (this.$g.noEmpty(t) && ~~t === 0) {
          console.log('可以发送')
          console.log('发送喽~')
          try {
            const params = { id: this.$route.query.id }
            // eslint-disable-next-line no-unused-vars
            const data = await this.$http('sendFinalDraftDownloadEmail', params)
            console.log(`data`, data)
            this.getOrderInfo()
          } catch (err) {
            this.$httpNotify(err)
          }
        } else {
          console.log('您已经发送过邮件了！')
        }
      } else {
        console.log('非客户端不允许发送邮件！')
      }
    },
  },
}
