<!--
 * @Author: gaojingran
 * @Date: 2021-04-15 09:21:21
 * @LastEditors: Please set LastEditors
 * @LastEditTime: 2022-08-11 16:43:05
 * @Description: 确认终稿
-->
<style lang="less" scoped>
.confirm-end-modal {
  .success-info {
    display: flex;
    align-items: center;
  }
  /deep/.ant-form-item {
    margin-bottom: 0px;
  }
}
</style>

<template>
  <a-modal
    :width="580"
    :title="$t('orderInfo.btn_submit_finish')"
    cancelText="暂不评价"
    :okText="$t('submit')"
    :maskClosable="false"
    :visible="visible"
    @cancel="handleCancel"
    @ok="handleOk"
  >
    <div class="confirm-end-modal">
      <div class="success-info">
        <a-icon class="success fs-4" type="check-circle" theme="filled" />
        <span class="pl-1">{{ $t('orderInfo.success_confirm') }}!</span>
      </div>
      <!-- <p
        class="mt-2 mb-2"
        v-html="
          $t('orderInfo.success_confirm_desc', {
            count: count,
            percent: percent + '%',
            money: money,
            unit: currencyUnit() && currencyUnit()[0],
          })
        "
      /> -->
      <p class="mb-1">{{ $t('orderInfo.success_confirm_rate_title') }}:</p>

      <a-form-model
        ref="ruleForm"
        :model="form"
        :rules="formRules"
        :label-col="{ span: 4 }"
        :wrapper-col="{ span: 20 }"
      >
        <a-form-model-item prop="timeliness" :label="$t('orderInfo.rate_label_delivery')">
          <a-rate v-model="form.timeliness" :allowClear="false" :allowHalf="true" />
        </a-form-model-item>

        <a-form-model-item prop="quality" :label="$t('orderInfo.rate_label_quality')">
          <a-rate v-model="form.quality" :allowClear="false" :allowHalf="true" />
        </a-form-model-item>

        <a-form-model-item prop="response" :label="$t('orderInfo.rate_label_response')">
          <a-rate v-model="form.response" :allowClear="false" :allowHalf="true" />
        </a-form-model-item>

        <a-form-model-item prop="attitude" :label="$t('orderInfo.rate_label_manner')">
          <a-rate v-model="form.attitude" :allowClear="false" :allowHalf="true" />
        </a-form-model-item>

        <a-form-model-item prop="satisfaction" :label="$t('orderInfo.rate_label_satisfaction')">
          <a-rate v-model="form.satisfaction" :allowClear="false" :allowHalf="true" />
        </a-form-model-item>

        <a-form-model-item prop="remark" :wrapper-col="{ span: 24 }">
          <a-textarea
            class="mt-2"
            v-model="form.remark"
            :maxLength="200"
            :placeholder="$t('orderInfo.rate_holder')"
            :rows="5"
          />
        </a-form-model-item>
      </a-form-model>
    </div>
  </a-modal>
</template>

<script>
import numeral from 'numeral'

const { validate } = window.$g

export default {
  name: 'ConfirmEndDraft',
  props: {
    // 订单详情
    info: {
      type: Object,
      default: () => ({}),
    },
    visible: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      loading: false,
      count: 0,
      percent: 0,
      money: 0,
      form: {
        attitude: 5,
        quality: 5,
        response: 5,
        timeliness: 5,
        satisfaction: 5,
        remark: '',
      },
    }
  },
  watch: {
    visible(val) {
      if (val) {
        this.getStatistic()
      } else {
        this.form = {
          attitude: 5,
          quality: 5,
          response: 5,
          timeliness: 5,
          satisfaction: 5,
          remark: '',
        }
        this.$refs.ruleForm.resetFields()
      }
    },
    form: {
      handler() {
        if (this.$refs.ruleForm) {
          this.$refs.ruleForm.clearValidate()
        }
      },
      deep: true,
    },
  },
  computed: {
    formRules() {
      const { attitude, quality, response, timeliness, satisfaction, remark } = this.form
      if (attitude || quality || response || timeliness || satisfaction || remark) {
        return {
          attitude: [validate.required],
          quality: [validate.required],
          response: [validate.required],
          timeliness: [validate.required],
          satisfaction: [validate.required],
          remark: [validate.size_200],
        }
      } else {
        return {}
      }
    },
  },
  methods: {
    // 币种单位
    currencyUnit() {
      if (this.$is.Defined(this.info.currency)) {
        const unit = this.$store.getters['app/getDictLabel']('CURRENCY', this.info.currency) || ''
        return unit.split(';')
      } else {
        return false
      }
    },

    async getStatistic() {
      try {
        const [count, percent, money] = await Promise.all([
          this.$http('orderStatisticCorpus', [this.info.id]),
          this.$http('orderStatisticTimeSaving', [this.info.id]),
          this.$http('orderStatisticCostSaving', [this.info.id]),
        ])
        this.count = count && count > 0 ? numeral(count).format('0.[00]') : 0
        this.money = money && money > 0 ? numeral(money).format('0.[00]') : 0
        this.percent = percent && percent > 0 ? numeral(percent * 100).format('0.[00]') : 0
      } catch (err) {
        this.$httpNotify(err)
      }
    },

    handleCancel(event, type = 0) {
      if (this.loading) return
      this.$emit('closeDrawer', type)
      this.$emit('update:visible', false)
      this.$bus.$emit('_refresh_order_info_')
    },

    async handleOk() {
      this.$refs.ruleForm.validate(async (valid) => {
        if (valid) {
          try {
            this.loading = true
            await this.$http('orderProcessProduceRemark', {
              ...this.form,
              orderId: this.info.id,
            })
            this.$emit('getAvaScore', this.avaScore())
            this.loading = false
            this.handleCancel(null, 1)
          } catch (err) {
            this.loading = false
            this.$httpNotify(err)
          }
        }
      })
    },
    avaScore() {
      const { attitude, quality, response, timeliness, satisfaction } = this.form
      const sum =
        Number(attitude ?? 0) +
        Number(quality ?? 0) +
        Number(response ?? 0) +
        Number(timeliness ?? 0) +
        Number(satisfaction ?? 0)
      const ava = sum / 5

      return ava
    },
  },
}
</script>
